<script>
import { mapGetters } from 'vuex';
import InfoBox from '@/components/InfoBox.vue';

export default {
  data() {
    return {
      orgnr: null,
      selectedType: null,
      selectedCountry: null,
    };
  },

  created() {
    console.log("Created hook - customer:", this.customer);
    this.orgnr = this.customer?.orgnr || null;
    this.selectedType = this.customer?.type || null;

    if (this.countryList && this.customer?.country) {
      const c = this.countryList.find((country) => country.code === this.customer.country.code);
      this.selectedCountry = c || null;
    }
  },

  watch: {
    selectedCountry(val, oldVal) {
      if (oldVal !== null) {
        this.orgnr = null;
      }
      console.log("Selected Country Changed:", val);
    },
  },

  computed: {
    ...mapGetters('checkout', ['customer', 'countryList', 'isLoading']),

    loading() {
      return this.isLoading;
    },

    collectVatNr() {
      if (this.selectedType && this.selectedCountry) {
        return this.selectedType === 'company' && this.selectedCountry.is_eu;
      }
      return false;
    },

    showDeliveryNotice() {
      if (this.selectedCountry) {
        return this.selectedType === 'personal' &&
          this.selectedCountry.code !== 'NO' &&
          !this.selectedCountry.is_eu &&
          !this.isSweden;
      }
      return false;
    },

    isSweden() {
      return this.selectedCountry && this.selectedCountry.code === 'SE';
    },
  },

  methods: {
    submit() {
      console.log("Submitting with:", {
        type: this.selectedType,
        country: this.selectedCountry,
        orgnr: this.orgnr,
      });
      this.$store.dispatch('checkout/setCustomer', {
        type: this.selectedType,
        country: this.selectedCountry,
        orgnr: this.orgnr,
      });

      this.$router.push({ name: 'checkoutInformation' });
    },
  },

  components: {
    InfoBox,
  },

  mounted() {
    document.title = this.$t("checkout.customerType");
  },
};
</script>

<template>
  <div class="my-6 mx-auto max-w-xl">
    <div class="my-3 text-left">
      <p class="text-4xl">{{ $t("checkout.customerType") }}</p>
    </div>

    <div class="bg-white w-full shadow-md flex mb-4">
      <button @click="selectedType = 'company'" class="flex-1 py-4" :class="[
        { 'bg-blue-500': selectedType === 'company' },
        selectedType === 'company' ? 'text-white' : 'text-blue-500'
      ]">
        <i class="fas fa-building"></i>
        {{ $t("checkout.company") }}
      </button>
      <button @click="selectedType = 'personal'" class="flex-1 py-4" :class="[
        { 'bg-blue-500': selectedType === 'personal' },
        selectedType === 'personal' ? 'text-white' : 'text-blue-500'
      ]">
        <i class="fas fa-user"></i>
        {{ $t("checkout.private") }}
      </button>
    </div>

    <div class="bg-white m-auto max-w-xl shadow-md">
      <div class="p-6">
        <div>
          <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-country">{{ $t('checkout.form.country') }}*</label>
          <select v-model="selectedCountry"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-country">
            <option value="">Select Country</option>
            <option v-for="(country, i) in countryList" :key="i" :value="country">
              {{ country.name_sv }}
            </option>
          </select>
        </div>

        <div class="my-4" v-if="collectVatNr">
          <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-vat-nr">
            {{ $t("checkout.vatNumber") }}
          </label>

          <input
            class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            id="grid-vat-nr" type="text" :placeholder="$t('checkout.vatNumber')" v-model="orgnr" />

          <p class="text-left text-xs mt-2">
            {{ $t("checkout.vatInfoText") }}
          </p>
        </div>

        <div class="my-4" v-if="showDeliveryNotice">
          <InfoBox type="info" title="OBS!" :description="$t('checkout.deliveryNoticeText')" />
        </div>
      </div>
    </div>

    <div class="flex justify-between">
      <localized-link :to="{ name: 'checkoutSummary' }"
        class="bg-gray-500 hover:bg-gray-600 text-white my-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        {{ $t("checkout.button.back") }}
      </localized-link>

      <button v-if="selectedType && selectedCountry && selectedCountry !== ''"
        class="bg-blue-500 hover:bg-blue-700 text-white my-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        @click="submit">
        {{ $t("checkout.button.next") }}
      </button>

      <button v-else class="bg-blue-500 text-white my-4 font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed">
        {{ $t("checkout.button.next") }}
      </button>
    </div>
  </div>
</template>
